import React, { useState } from 'react';
import JobOffer from '../components/JobOffer';

const Jobs = ({ english, offers }) => {
  const [search, setSearch] = useState('');
  const [workMode, setWorkMode] = useState('');
  const [contractType, setContractType] = useState('');

  // Location
  let optionsPl = [...new Set(offers.map((offer) => offer.locationPl))];
  let optionsEn = [...new Set(offers.map((offer) => offer.locationEn))];

  // Word Mode
  let workModesPl = [
    ...new Set(offers.map((offer) => offer.workModePl).filter(Boolean)),
  ];
  let workModesEn = [
    ...new Set(offers.map((offer) => offer.workModeEn).filter(Boolean)),
  ];
  // Contract Type
  let contractTypesPl = [
    ...new Set(offers.map((offer) => offer.contractTypePl).filter(Boolean)),
  ];
  let contractTypesEn = [
    ...new Set(offers.map((offer) => offer.contractTypeEn).filter(Boolean)),
  ];

  // Filtering
  const filteredOffers = offers.filter((item) => {
    const matchesLocation =
      search === '' ||
      (!english
        ? item.locationPl.toLowerCase().includes(search)
        : item.locationEn.toLowerCase().includes(search));

    const matchesWorkMode =
      workMode === '' ||
      (!english ? item.workModePl === workMode : item.workModeEn === workMode);

    const matchesContractType =
      contractType === '' ||
      (!english
        ? item.contractTypePl === contractType
        : item.contractTypeEn === contractType);

    return matchesLocation && matchesWorkMode && matchesContractType;
  });

  return (
    <section id='jobs' className='jobs'>
      <div className='maincontainer'>
        <h2 className='section-name'>{!english ? 'oferty' : 'offers'}</h2>
        <h2 className='section-title'>
          {!english
            ? 'Szukasz idealnej oferty? Mamy ich wiele, znajdź coś dla siebie!'
            : 'Looking for the perfect offer? We have many, find something for you!'}
        </h2>
        {/* Filters */}
        <div className='jobFilter'>
          <div className='jobFilter__inner'>
            {/* Location Filter */}
            <h3 className='jobFilter__title'>
              {!english ? 'Wybierz lokalizację' : 'Choose location'}
            </h3>
            <select
              className='jobFilter__select'
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            >
              <option value=''>{!english ? 'Wszystkie' : 'All'}</option>
              {(!english ? optionsPl : optionsEn).map((option, index) => (
                <option key={index} value={option.toLowerCase()}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className='jobFilter__inner'>
            {/* Work Mode Filter */}
            <h3 className='jobFilter__title'>
              {!english ? 'Tryb pracy' : 'Work mode'}
            </h3>
            <select
              className='jobFilter__select'
              onChange={(e) => setWorkMode(e.target.value)}
            >
              <option value=''>{!english ? 'Wszystkie' : 'All'}</option>
              {(!english ? workModesPl : workModesEn).map((mode, index) => (
                <option key={index} value={mode}>
                  {/* {!english ? mode : mode} */}
                  {mode}
                </option>
              ))}
            </select>
          </div>
          <div className='jobFilter__inner'>
            {/* Contract Type Filter */}
            <h3 className='jobFilter__title'>
              {!english ? 'Rodzaj zatrudnienia' : 'Contract type'}
            </h3>
            <select
              className='jobFilter__select'
              onChange={(e) => setContractType(e.target.value)}
            >
              <option value=''>{!english ? 'Wszystkie' : 'All'}</option>
              {(!english ? contractTypesPl : contractTypesEn).map(
                (type, index) => (
                  <option key={index} value={type}>
                    {/* {!english ? type : type} */}
                    {type}
                  </option>
                )
              )}
            </select>
          </div>
        </div>

        <div className='jobsContainer'>
          {filteredOffers.map((offer, index) => (
            <JobOffer
              key={index}
              english={english}
              titlePl={offer.jobTitlePl}
              titleEn={offer.jobTitleEn}
              locationPl={offer.locationPl}
              locationEn={offer.locationEn}
              workModePl={offer.workModePl || ''}
              workModeEn={offer.workModeEn || ''}
              contractTypePl={offer.contractTypePl || ''}
              contractTypeEn={offer.contractTypeEn || ''}
              link={offer.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Jobs;
