import React, { useState, useEffect } from 'react';
import useContentful from './useContentful';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.scss';
import Navbar from './components/Navbar';
import About from './sections/About';
import Benefits from './sections/Benefits';
import Csr from './sections/Csr';
import Footer from './sections/Footer';
import Header from './sections/Header';
import Intro from './sections/Intro';
import Jobs from './sections/Jobs';
import People from './sections/People';
import Values from './sections/Values';
import NavbarMobile from './components/NavbarMobile';
import FooterMobile from './sections/FooterMobile';
import Cookies from './components/Cookies';

function App({ nonce }) {
  const [offers, setOffers] = useState([]);
  const { getOffers } = useContentful();
  useEffect(
    () => {
      AOS.init();
      AOS.refresh();
      getOffers().then((response) => setOffers(response));
    },
    // [getOffers]);
    []
  );
  // useEffect(() => {
  //   if (nonce) {
  //     const script = document.createElement('script');
  //     script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-NLD9RXF';
  //     script.async = true;
  //     script.nonce = nonce;
  //     document.head.appendChild(script);
  //   }
  // }, []);
  const [english, setEnglish] = useState(false);
  return (
    <div className='App'>
      <script
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `
            console.log('Nonce used: ${nonce}');
          `,
        }}
      ></script>
      <iframe
        src='https://www.googletagmanager.com/ns.html?id=GTM-NLD9RXF'
        title='GTM'
        style={{ display: 'none', visibility: 'hidden' }}
        width='0'
        height='0'
        nonce={nonce}
      ></iframe>

      <Navbar setEnglish={setEnglish} english={english} />
      <NavbarMobile setEnglish={setEnglish} english={english} />
      <Header setEnglish={setEnglish} english={english} />
      <Cookies setEnglish={setEnglish} english={english} />
      <Intro setEnglish={setEnglish} english={english} />
      <About setEnglish={setEnglish} english={english} />

      <Jobs
        setEnglish={setEnglish}
        english={english}
        offers={offers}
        setOffers={setOffers}
      />
      <Benefits setEnglish={setEnglish} english={english} />
      <Values setEnglish={setEnglish} english={english} />
      <People setEnglish={setEnglish} english={english} />
      <Csr setEnglish={setEnglish} english={english} />
      <Footer setEnglish={setEnglish} english={english} />
      <FooterMobile setEnglish={setEnglish} english={english} />
    </div>
  );
}

export default App;
