import React from 'react';

const About = ({ english, setEnglish }) => {
  return (
    <section id='about' className='about'>
      <div className='maincontainer'>
        <h2 className='section-name'>{!english ? 'o nas' : 'about us'}</h2>
        <div className='about__content'>
          <div className='about__content-info'>
            <h2 className='about__content-info--title section-title'>
              {!english
                ? 'TP to globalna firma świadcząca cyfrowe usługi biznesowe. '
                : 'TP is a global digital business services company. '}
            </h2>
            <p className='about__content-info--description section-description'>
              {!english
                ? 'Dzięki prawie 500 000 inspirujących, pełnych pasji pracownikom na świecie, którzy mówią w ponad 300 różnych językach oraz naszej globalnej skali działania i lokalnej obecności wspieramy naszych klientów poprzez wielokanałową, spersonalizowaną i kompleksową obsługę procesów związanych z pozyskaniem, obsługą i utrzymaniem klienta. Zapewniamy zintegrowane podejście w zakresie zarządzania doświadczeniami klientów, back-office i optymalizacji. Zgodnie z naszym podejściem „High-Tech, High-Touch” dostarczamy najbardziej zaawansowane, cyfrowe usługi biznesowe, aby w znaczący i zrównoważony sposób pomóc najlepszym markom na świecie rozwijać ich działalność. '
                : "With nearly 500,000 inspiring, passionate employees worldwide who speak more than 300 different languages, and our global scale and local presence, we support our customers through multi-channel, personalized and end-to-end customer acquisition, service and retention processes. We provide an integrated approach in customer experience management, back-office and optimization. In line with our “High-Tech, High-Touch” approach, we deliver the most advanced digital business services to help the world's best brands grow their businesses in a meaningful and sustainable way.  "}
              <br />
              <br />
              {!english
                ? 'TP obecne jest na polskim rynku nieprzerwanie od 1997 roku. Nasze biura znajdziesz w  Warszawie, Krakowie, Poznaniu i Katowicach.'
                : 'TP has been present in the Polish market continuously since 1997. You can find our offices in Warsaw, Krakow, Poznan and Katowice.'}
            </p>
            <a href='#jobs'>
              <button className='about__content-button button'>
                {!english ? 'Dołącz do nas już dziś!' : 'Join us today!'}
              </button>
            </a>
          </div>
          <div className='about__content-stats'>
            <div className='about__content-stats--stat about__content-stats--stat-one'>
              <span className='stat-text-gray'>
                {!english
                  ? 'Obecnie współpracujemy z'
                  : 'We are currently working with'}
              </span>
              <span className='stat-number text-gradient'>3817</span>
              <span className='stat-text-pink'>
                {!english ? 'osobami pełnymi pasji' : 'passionate individuals'}
              </span>
            </div>
            <div className='about__content-stats--stat about__content-stats--stat-two'>
              <span className='stat-text-gray'>
                {!english ? 'obywatelami' : 'citizens of'}
              </span>
              <span className='stat-number text-gradient'>77</span>
              <span className='stat-text-pink'>
                {!english ? 'różnych krajów' : 'different countries'}
              </span>
            </div>
            <div className='about__content-stats--stat about__content-stats--stat-three'>
              <span className='stat-text-gray'>
                {!english
                  ? 'którzy wspieraja naszych Klientów w'
                  : 'who support our clients in'}
              </span>
              <span className='stat-number text-gradient'>32</span>
              <span className='stat-text-pink'>
                {!english ? 'językach' : 'languages'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
