import React from 'react';

const Header = ({ english }) => {
  return (
    <header className='header' id='home'>
      <div className='maincontainer title-container'>
        <h1 className='title-container__title'>
          {!english ? 'Chesz wykorzystać swoje ' : 'Want to use '} <br />
          {!english ? 'umiejętności językowe ' : 'your language skills '} <br />
          {!english ? 'i rozwijć się ' : 'and grow in an  '} <br />
          {!english ? 'w międzynarodowym ' : 'international '} <br />
          {!english ? 'środowisku?' : 'environment '}
          <br />
          <br />
          {!english ? 'Z nami to możliwe!' : 'It’s possible with us!'}
        </h1>
        <a href='#jobs'>
          <button className='title-container__button'>
            {!english ? 'Sprawdź oferty' : 'Search jobs'}
          </button>
        </a>
      </div>
    </header>
  );
};

export default Header;
