import React from 'react';
import IntroImg from '../assets/images/intro.jpg';

const Intro = ({ english }) => {
  return (
    <section id='intro'>
      <div className='maincontainer'>
        <div className='intro__content'>
          <div className='intro__content-image'>
            <img src={IntroImg} alt='Grupa ludzi patrząca na telefon' />
          </div>
          <div className='intro__content-info'>
            <h2 className='intro__content-info--title section-title'>
              {!english ? 'Jak?' : 'How is it possible?'}
            </h2>
            {!english ? (
              <p className='intro__content-info--description section-description'>
                W TP codziennie współpracujesz z ludźmi z całego świata,
                rozwijasz swoje umiejętności językowe i zdobywasz cenne
                doświadczenie w międzynarodowym środowisku. Niezależnie od tego,
                czy wspierasz globalne marki, rozwiązujesz realne problemy
                klientów czy uczysz się nowych technologii – tutaj każdy dzień
                przynosi nowe wyzwania i możliwości.{' '}
                <strong>
                  Dołącz do nas i zobacz, dokąd może zaprowadzić Cię ta
                  przygoda!
                </strong>
              </p>
            ) : (
              <p className='intro__content-info--description section-description'>
                At TP, you work with people from all over the world every day,
                develop your language skills and gain valuable experience in an
                international environment. Whether you are supporting global
                brands, solving real customer problems or learning new
                technologies - here every day brings new challenges and
                opportunities.{' '}
                <strong>
                  Join us and see where this adventure can take you!
                </strong>
              </p>
            )}
          </div>
        </div>
        <div className='intro__banner'>
          <p
            className='banner__h1'
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-offset='100'
          >
            {!english ? 'Nasza obietnica.' : 'Our promise.'}{' '}
          </p>
          <br />
          <p
            className='banner__h1'
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-delay='300'
            data-aos-offset='100'
          >
            {!english ? 'Simpler. Faster. Safer.' : 'Simpler. Faster. Safer.'}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Intro;
