import image1 from '../assets/images/slider/1.jpg';
import image2 from '../assets/images/slider/2.jpg';
import image3 from '../assets/images/slider/3.jpg';
import image4 from '../assets/images/slider/4.jpg';
import image5 from '../assets/images/slider/5.jpg';
import image6 from '../assets/images/slider/6.jpg';
import image7 from '../assets/images/slider/7.jpg';
import image8 from '../assets/images/slider/8.jpg';
import image9 from '../assets/images/slider/9.jpg';
import image10 from '../assets/images/slider/10.jpg';
import image11 from '../assets/images/slider/11.jpg';

const images = [
  // {
  //   src: image2,
  //   textPl: 'Prywatna opieka medyczna',
  //   textEn: 'Private Medical Care',
  // },

  // {
  //   src: image4,
  //   textPl: 'Ubezpieczenie na życie',
  //   textEn: 'Private Life insurance',
  // },
  // {
  //   src: image5,
  //   textPl: 'Elastyczny czas pracy',
  //   textEn: 'Flexible Working Time',
  // },
  {
    src: image1,
    textPl: 'Platforma MyBenefit ',
    textEn: 'MyBenefit platform ',
  },
  {
    src: image3,
    textPl: 'Platforma wsparcia psychologicznego i wellbeing',
    textEn: 'Psychological support and wellbeing platform ',
  },
  {
    src: image5,
    textPl: 'Platforma do nauki języków obcych',
    textEn: 'Platform for language learning',
  },
  {
    src: image6,
    textPl: 'Program poleceń',
    textEn: 'Referral program ',
  },
  {
    src: image7,
    textPl: 'Wydarzenia integracyjne',
    textEn: 'Integration events',
  },

  {
    src: image11,
    textPl: 'Inicjatywy charytatywne',
    textEn: 'Charity Initiatives',
  },
  {
    src: image8,
    textPl: 'Quizy i konkursy',
    textEn: 'Quizzes and contests',
  },
  { src: image10, textPl: 'Brak dress-code’u', textEn: 'No Dress Code' },
];

export default images;
