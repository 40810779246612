const cards = [
  {
    titlePl: 'Poprawa życia na całym świecie',
    titleEn: 'Improving lives around the world',
    infoPl:
      'Wierzymy w wizję przedstawioną przez Global Impact Sourcing Initiative: możliwość podjęcia produktywnego zajęcia przez każdego człowieka.',
    infoEn:
      'We believe in the vision set forth by the Global Impact Sourcing Initiative: the opportunity for every person to take a productive occupation.',
    hash: '#inclusion',
  },
  {
    titlePl: 'Minimalizacja wpływu na planetę',
    titleEn: 'Minimizing impact on the planet',
    infoPl:
      'Dzięki inicjatywie Citizen of the Planet jesteśmy zaangażowani w różne działania mające na celu ochronę naszej planety. ',
    infoEn:
      'Through the Citizen of the Planet initiative, we are involved in various activities to protect our planet. ',
    hash: '#CitizenofthePlanet',
  },
  {
    titlePl: 'Idealne miejsce  rozwoju',
    titleEn: 'The ideal place for development',
    infoPl:
      'Zależy nam, aby ludzie, tworzący nasze zespoły,  byli zadowoleni z oferowanych waunków oraz w pełni usatysfakcjonowani z ról, którą pełnią w organizacji. Dbamy o rozwój i dobrostan, poprzez dostęp do programów szkoleniowych i wellbeingowych. ',
    infoEn:
      'We are committed to ensuring that the people who make up our teams are satisfied with the conditions offered and fully satisfied with the roles they play in the organization. We care about development and wellbeing, through access to training and wellbeing programs. ',
    hash: '#Apreferredemployer',
  },
  {
    titlePl: 'Różnorodny, równy i inkluzywny zespół',
    titleEn: 'A diverse, equal and inclusive team',
    infoPl:
      'Szacunek, otwartość, uczciwość. Nasze różnice czynią nas silniejszymi, a razem możemy więcej!',
    infoEn:
      'Respect, openness, honesty. Our differences make us stronger, and together we can do more!',
    hash: '#Diversity',
  },
  {
    titlePl: 'Prawa człowieka i środowisko',
    titleEn: 'Human rights and the environment',
    infoPl:
      'Polityka naszej organizacji jest zgodna z zasadmi UN Global Compact dotyczącymi praw człowieka, pracy, środowiska i przeciwdziałania korupcji.',
    infoEn:
      "Our organization's policies are in line with the UN Global Compact's principles on human rights, labor, environment and anti-corruption.",
    hash: '#Globalcompact',
  },
];

export default cards;
