import React from 'react';
import People1 from '../assets/images/people1.webp';
import People2 from '../assets/images/people2.webp';
import People3 from '../assets/images/people3.webp';

const People = ({ english }) => {
  return (
    <section id='people'>
      <div className='maincontainer'>
        <h2 className='section-name'>
          {!english ? 'nasi ludzie' : 'our people'}
        </h2>
        <h2 className='people__content-info--title section-title'>
          {!english
            ? 'Angażujemy i rozwijamy'
            : 'We engage and develop the best '}{' '}
          <br />{' '}
          {!english ? 'największe talenty w branży' : 'talent in the industry'}
        </h2>
        <div className='people__content'>
          <div className='people__content--images'>
            <div
              className={!english ? 'images__left' : 'images__left--english'}
            >
              <img src={People1} alt='' />
            </div>
            <div
              className={!english ? 'images__right' : 'images__right--english'}
            >
              <div
                className={
                  !english
                    ? 'images__right--top'
                    : 'images__right--english--top'
                }
              >
                <img src={People2} alt='' />
              </div>
              <div
                className={
                  !english
                    ? 'images__right--bottom'
                    : 'images__right--english--bottom'
                }
              >
                <img src={People3} alt='' />
              </div>
            </div>
          </div>
          <div className='people__content--info'>
            <p>
              {!english
                ? 'Stosujemy odpowiedzialne, inkluzywne i skuteczne praktyki rekrutacyjne, aby przyciągnąć najlepsze talenty, a następnie kładziemy nacisk na rozwój, aby zmaksymalizować potencjał każdego z nas. '
                : "We use responsible, inclusive and effective recruitment practices to attract the best talent, and then emphasize development to maximize everyone's potential."}
              <br />
              <br />
              {!english
                ? 'Różnorodność, inkluzywność i równość to podstawy naszej kultury. Jesteśmy najbardziej zróżnicowanym  dostawcą usług biznesowych w branży – to dla nas niezywkła wartość, z której czerpiemy siłę do działania, tworząc niezwykły zespół. '
                : 'Diversity, inclusiveness and equality are the cornerstones of our culture. We are the most diverse business services provider in the industry - an incredible value for us, and one that we draw from to create an extraordinary team. '}
            </p>
          </div>
        </div>
        <div className='people__banner banner'>
          <p
            className='banner__h1'
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-offset='400'
            data-aos-delay='400'
          >
            {!english
              ? 'Jesteś ambitny? My również!'
              : 'Are you ambitious? So are we!'}
          </p>
        </div>
      </div>
    </section>
  );
};

export default People;
