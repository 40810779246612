import React from 'react';

const JobOffer = (props) => {
  return (
    <a href={props.link} target='_blank' rel='noreferrer'>
      <div className='jobOffer'>
        {/* <div className='jobOfferTop'> */}
        <h2 className='jobOfferTitle'>
          {!props.english ? `${props.titlePl}` : `${props.titleEn}`}
        </h2>
        {/* <br /> */}
        {/* <br /> */}
        <h4 className='jobOfferLocation'>
          {!props.english ? `${props.locationPl}` : `${props.locationEn}`}
        </h4>
        {/* <br /> */}
        <h4 className='jobOfferWorkType'>
          {!props.english ? `${props.workModePl}` : `${props.workModeEn}`}
        </h4>
        {/* <br /> */}
        <h4 className='jobOfferContractType'>
          {!props.english
            ? `${props.contractTypePl}`
            : `${props.contractTypeEn}`}
        </h4>
        {/* </div> */}
        <div className='jobOfferBottom'>
          <button className='jobOfferButton'>
            {!props.english ? 'Aplikuj teraz' : 'Apply Now'}
          </button>
        </div>
      </div>
    </a>
  );
};

export default JobOffer;
