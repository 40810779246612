import React from 'react';
import ValuesImg from '../assets/images/values.webp';

const Values = ({ english }) => {
  return (
    <section id='values'>
      <div className='maincontainer-margin-right'>
        <div className='values__content'>
          <div className='values__content-image'>
            <img src={ValuesImg} alt='' />
          </div>
          <div className='values__content-info'>
            <h2 className='section-name section-name--white'>
              {!english ? 'nasze wartości' : 'our values'}
            </h2>
            <h2 className='values__content-info--title section-title'>
              {!english
                ? 'Międzynarodowe środowisko i świetna atmosfera'
                : 'International environment and great atmosphere'}
            </h2>
            <p className='values__content-info--description section-description'>
              {!english
                ? 'Wiemy, że to ludzie stanowią największy kapitał naszej organizacji. Relacje i pracę zespołową stawiamy zawsze na pierwszym miejscu. Inwestujemy w przestrzeń, którainspiruje do rozwoju i realizacji celów. Nasza kultura opiera sie współpracy, różnorodności i wzajemny wsparciu,  bo Twoje sprawy to nasze sprawy. Razem budujemy partnerskie relacje, które zdają egzamin.'
                : 'We know that people are the greatest asset of our organization. We always put relationships and teamwork first. We invest in a space that inspires development and achievement of goals. Our culture is based on cooperation, diversity and mutual support, because your business is our business. Together we build partnerships that pass the test.'}
            </p>
          </div>
        </div>
      </div>
      <div className='values__banner maincontainer'>
        <p className='banner__h1' data-aos='fade-up' data-aos-duration='1000'>
          {!english ? 'Czekamy właśnie na Ciebie!' : 'We are waiting for you!'}
        </p>
      </div>
      <a href='#jobs'>
        <button className='values__content-button button'>
          {!english ? 'Dołącz do nas już dziś!' : 'Join us today!'}
        </button>
      </a>
    </section>
  );
};

export default Values;
